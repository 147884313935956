import Lenis from "@studio-freight/lenis";

export const lenis = new Lenis({
  duration: 1.2,
  easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
  smoothWheel: true,
});

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}

$("#menu a").click((e) => {
  let index = $("#menu a").index(e.currentTarget);
  let scrollTo = index + 1;
  if (index > 1) {
    scrollTo += 1;
  }
  lenis.scrollTo(`#page${scrollTo}`);
});

const changeMainContainerWidth = () => {
  if (window.innerWidth > 768) {
    let width = 100 - (20 * window.scrollY) / window.innerHeight;
    if (width < 85) width = 85;

    $("#right-container").css("width", `${width}vw`);
    $("#left-container").css("width", `${100 - width}vw`);
  }

  const percentage =
    window.scrollY /
    (document.documentElement.scrollHeight - window.innerHeight);

  $("#pgogression-bar-active").css("width", `${percentage * 100}%`);
  $("#pgogression-bar-unactive").css("width", `${100 - percentage * 100}%`);
};

const loader = () => {
  $("#loader p").each(function (index) {
    const timeout1 = setTimeout(() => {
      $(this).css("transform", "translateY(0%)");
    }, 100 * index);

    const timeout2 = setTimeout(() => {
      $(this).css("transform", "translateY(-100%)");
    }, 1000 + 100 * index);
    window._config.timouts.push(timeout1, timeout2);
  });

  $("#page1 h2").each(function (index) {
    $(this).css("transform", "translateY(100%)");
  });
  $("#menu a p").each(function (index) {
    $(this).css("transform", "translateY(100%)");
  });

  const timeout3 = setTimeout(() => {
    $("#loader").css("transform", "translateY(-100%)");
    setTimeout(() => {
      $("#loader").css("display", "none");
    }, 700);
    $("#page1 h2").each(function (index) {
      const timeout4 = setTimeout(() => {
        $(this).css("transform", "translateY(0%)");
      }, 100 * index);

      window._config.timouts.push(timeout4);
    });

    const timeout5 = setTimeout(() => {
      $("#menu a p").each(function (index) {
        $(this).css("transform", "translateY(0%)");
      });
    }, 100 * $("#page1 h1").length);
    window._config.timouts.push(timeout5);
    requestAnimationFrame(raf);
  }, 1100 + $("#loader p").length * 100);
  window._config.timouts.push(timeout3);
};

window.addEventListener("scroll", function (e) {
  if (!window._config.isLoading)
    this.requestAnimationFrame(changeMainContainerWidth);
});

// const submitForm = async (e) => {
//   e.preventDefault();

//   const form = e.target;
//   const formAction = form.getAttribute("action")
//     ? form.getAttribute("action").trim()
//     : "#";
//   const formMethod = form.getAttribute("method")
//     ? form.getAttribute("method").trim()
//     : "GET";
//   const formData = new FormData(form);

//   const response = await fetch(formAction, {
//     method: formMethod,
//     body: formData,
//   });

//   if (response.ok) {
//     alert("form sent !");
//   } else {
//     alert("Error");
//   }
// };

document.addEventListener("DOMContentLoaded", function () {});

window.addEventListener("DOMContentLoaded", function () {
  window._config = {
    timouts: [],
  };

  loader();

  window.onCaptchaSuccess = function () {
    document.getElementById("captcha").style.display = "none";
    document.getElementById("submitButton").style.display = "block";
  };
});

window.addEventListener("load", function () {
  window.scrollTo(0, 0);
});

window.addEventListener("pagehide", function (e) {
  window._config.timouts.forEach((element) => {
    clearTimeout(element);
  });
  window.scrollTo(0, 0);
});
